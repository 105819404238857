import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

  export default {
    fillable_by:FillableBy.Both,
    title:
      "ECMO Clinical Competency Assesment Form",
    sections: [
      {
        title: "Competency Statement: During orientation, and on a annual basis thereafter, the employee's clinical competencies shall be assessed.",
        type: "table",
        heads: constants.tableHeads,
        rows :[
            "Demonstrates process to set-up, start, and shut- down equipment, including appropriate use of checklists",
            "Operates equipment safely, appropriately, and according to patient’s needs",
            "Performs required monitoring of ECMO and related equipment during patient treatment as per facility policy/procedure",
            "Cleans/disinfects and stores equipment as per manufacturers recommendations and facility policy/procedures",
            "Accurately documents: Set-up Monitoring Preventative and routine maintenance Cleaning/disinfection Equipment malfunctions/ failure",
            "Responds to alarms in a timely manner",
            "Troubleshoots pump and related equipment alarms in an organized and timely manner",
            "Initiates appropriate and timely interventions based upon alarm data and pump/equipment assessment, including taking patient off ECMO",
            "Demonstrates procedures for routine replacement of ECMO pump and related equipment as per facility policy/procedure",
            "Demonstrates procedures for emergency replacement of ECMO pump and related equipment as per facility policy/procedure",
            "Identifies process to document equipment failure",
            "Identifies process to have equipment repaired",
            "Understands temperature alarm limits, over temp alarms, and ranges for Stryker T/Pump Professional HC Unit",
            "Demonstrates technique for handcranking",
        ].map((item) => ([{ text: item, type: 'text' }, ...table_fields]))
      },
      {...evaluationCheckList},
      {
        title: "Signatures",
        type: "signature",
        fields: getSignatures(FillableBy.Both)
      },
    ]
  }